/* Ensure the LanguageSelector is positioned at the top right corner */
.language-selector.top-right-corner {
    position: absolute;
    top: 30px;  /* Adjust this value to your preference */
    right: 50px;  /* Adjust this value to your preference */
    z-index: 1000;  /* Ensure it appears on top */
}

/* Override styles for the language selector button */
.language-selector .dropdown-toggle.btn.btn-primary {
    background-color: var(--brand-color) !important; /* Change to your desired color */
    color: white !important; /* Change to your desired text color */
    border: none !important; /* Remove border if desired */
    font-size: 14px !important; /* Change to your desired font size */
}

/* Override styles for the language selector button hover, focus, and active states */
.language-selector .dropdown-toggle.btn.btn-primary:hover,
.language-selector .dropdown-toggle.btn.btn-primary:focus,
.language-selector .dropdown-toggle.btn.btn-primary:active {
    background-color: var(--brand-color) !important; /* Change to your desired hover/focus color */
    color: var(--primaryColor) !important; /* Ensure text color is appropriate */
}

/* Override styles for the dropdown menu */
.language-selector .dropdown-menu {
    background-color: #f8f9fa !important; /* Change to your desired dropdown menu background color */
    color: rgb(206, 9, 9) !important; /* Change to your desired dropdown menu text color */
    border: 1px solid #ddd !important; /* Adjust border color if needed */
}

.language-selector .dropdown {
    background-color: white;
    color: black !important; /* Change to your desired item text color */
    font-size: 14px !important; /* Change to your desired item font size */
}

/* Override styles for dropdown item hover, focus, and active states */
.language-selector .dropdown-item:hover,
.language-selector .dropdown-item:focus,
.language-selector .dropdown-item:active {
    background-color: #e9ecef !important; /* Change to your desired hover/focus item background color */
    color: black !important; /* Ensure text color is appropriate */
}

/* Override styles for the show class */
.language-selector .show {
    background-color: aqua;
    color: #030d18;
    display: block !important; /* Ensure dropdown is displayed correctly */
}
/* Override styles for dropdown items */
.language-selector .dropdown-item {
    color: black !important; /* Change to your desired item text color */
    font-size: 14px !important; /* Change to your desired item font size */
    background-color: white !important; /* Set background color to white */
}
