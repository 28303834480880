/* RotatingLoader.css */

.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh; /* Full screen height */
  }
  
  .rotating-image {
    width: 100px; /* Adjust size as needed */
    height: 100px; /* Adjust size as needed */
    animation: rotate 2s linear infinite;
  }
  
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  /* Center the spinner */
#loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa; /* Light background */
  z-index: 1000;
  animation: flip 2s linear infinite;
  transform-style: preserve-3d;

}

/* 3D flipping animation */
@keyframes flip {
  0% { transform: perspective(400px) rotateY(0deg); }
  50% { transform: perspective(400px) rotateY(180deg); }
  100% { transform: perspective(400px) rotateY(360deg); }
}

.flip-image {
  width: 100px; /* Adjust size as needed */
  height: 100px; /* Adjust size as needed */
  animation: flip 2s linear infinite;
}
