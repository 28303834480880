/* DarkModeToggle.css */

body {
    --icon-color: #000;
    --icon-hover-color: #555;
    background-color: #ffffff;
    color: #000000;
  }
  
  .dark-mode {
    --icon-color: #fff;
    --icon-hover-color: #f0f0f0;
    background-color: #121212;
    color: #ffffff;
  }
  
  /* Navbar styles */
  .navbar-dark-mode {
    background-color: #343a40;
    color: #ffffff;
  }
  
  .navbar-dark-mode .navbar-nav .nav-link {
    color: #ffffff;
  }
  
  .navbar-dark-mode .navbar-brand {
    color: #ffffff;
  }
  
  .navbar-dark-mode .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%288, 8, 8, 0.5%29' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }
  
  .btn-light-mode {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: var(--icon-color);
    transition: color 0.3s ease;
  }
  
  .btn-light-mode:hover {
    color: var(--icon-hover-color);
  }
  
  .switch-button {
    padding: 0.5rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  